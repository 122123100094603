.section {
  display: flex;
  gap: 100px;
  height: 600px;
  background-color: white;
  flex: 1;
  align-items: center;
  justify-content: center;
}

.section-text {
  text-align: left;
  width: 60%;
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin-left: 5%;
}

.section-text-h1 {
  font-size: 55px;
  text-shadow: 1px 1px #fff;
  text-align: left;
  margin: 0;
}

.section-text-h2 {
  font-size: 25px;
  text-shadow: 1px 1px #fff;
  text-align: left;
  margin: 0;
  padding: 5px;
}

.section-text-p {
  font-size: 15px;
  text-align: left;
  margin: 0;
  padding-top: 10px;
}

.section-img {
  display: flex;
  align-items: center;
  width: 400px;
  height: 400px;
  margin-right: 5%;
}

.section-img-img {
  max-width: 100%;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #707070);
}

@media screen and (max-width: 768px) {
  .section {
    flex-direction: column-reverse;
    height: auto;
  }
}

@media screen and (max-width: 768px) {
  .section-text {
    margin-left: 0;
    padding-bottom: 50px;
  }
}

@media screen and (max-width: 768px) {
  .section-text-h1,
  .section-text-h2,
  .section-text-p {
    text-align: center;
  }
}

@media screen and (max-width: 768px) {
  .section-img {
    margin-right: 0;
    height: auto;
    padding-top: 50px;
    padding-bottom: 20px;
  }
}
