.contact {
  background-color: #c4dfff;
  margin: 0;
  height: 500px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  justify-content: space-evenly;
  padding-bottom: 20px;
}

form {
  display: inline-block;
  padding: 1em;
  border: 4px solid #a1cbfe;
  border-radius: 1em;
  background-color: #c4dfff;
  width: 50%;
  max-width: 400px;
  -webkit-filter: drop-shadow(5px 5px 5px #222);
  filter: drop-shadow(5px 5px 5px #707070);
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

form li + li {
  margin-top: 1em;
}

label {
  display: flex;
  min-width: 90px;
  text-align: right;
  padding-bottom: 5px;
  color: black;
  font-size: 20px;
  text-shadow: 1px 1px 1px rgb(172, 172, 172);
}

input,
textarea {
  font: 1em sans-serif;
  width: 100%;
  box-sizing: border-box;
  border: none;
  border-radius: 3px;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
}

input:focus,
textarea:focus {
  border-color: #000;
}

textarea {
  vertical-align: top;
  height: 5em;
}

.button {
  text-align: center;
}

button {
  margin-left: 0;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  border: none;
  background-color: white;
  width: 80px;
  height: 30px;
  font-size: 20px;
}

.contactform-send-message {
  color: black;
  text-decoration: none;
  text-align: center;
  font-size: 24px;
  font-weight: 550;
  font-family: "Gill Sans", "Gill Sans MT", Calibri, "Trebuchet MS", sans-serif;
  text-shadow: 1px 1px 1px rgb(172, 172, 172);
  padding: 20px 0;
}

.success {
  color: black;
  font-weight: bold;
  padding-top: 20px;
}

.error {
  color: black;
  font-weight: bold;
  padding-top: 20px;
}
